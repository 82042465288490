import React from 'react'
import Tiles from '../../../react4xp/components/Tiles'
import Card from '../../../react4xp/components/Card'
import FeatureCard from '../../../react4xp/components/FeatureCard'
import ReviewCard from '../../../react4xp/components/ReviewCard'
import NewsCard from '../../../react4xp/components/NewsCard'
import ProjectCard from '../../../react4xp/components/ProjectCard'
import ServiceCard from '../../../react4xp/components/ServiceCard'
import SectionHeader from '../../../react4xp/components/SectionHeader'


function TilesPart({ heading, preHeading, subHeading, cards, strings }) {
  function returnRespectiveCardType(style, props) {
    switch (style) {
      case 'featureCard':
        return <FeatureCard {...props} label={props.title} title={props.ingress} />
      case 'newsCard':
        return <NewsCard {...props} />
      case 'projectCard':
        return <ProjectCard {...props} label={props.title} title={props.ingress} />
      case 'serviceCard':
        return <ServiceCard {...props} subTitle={props.ingress} />
      default:
        return <Card {...props} />
    }
  }

  return (
    <Tiles
      sectionHeader={
        <SectionHeader
          layout='vertical'
          labelText={preHeading}
          title={heading}
          subtitle={subHeading}
          bulletColor='accent-1'
          strings={strings}
        />
      }
      cards={cards && cards.map(review => (
        returnRespectiveCardType(review.style, {
          key: review.title,
          buttonText: review.linkText,
          href: review.item,
          imgSrc: review.imageSrc?.link,
          imgSrcMedium: review.imageSrcMedium?.link,
          imgAlt: review.imageSrc?.alt,
          imgWidth: 960,
          imgHeight: 960,
          title: review.title,
          ingress: review.intro,
          color: review.color
        })
      ))}
    />
  )
}

export default (props) => <TilesPart {...props} />
